// pages/auth/actions.js
import { useEffect, useState } from 'react';
import {useNavigate, useLocation } from 'react-router';
import { applyActionCode, verifyPasswordResetCode } from 'firebase/auth';
import {auth} from '../../utils/firebase';


const AuthActions = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get specific query parameters
  const mode = queryParams.get('mode');
  const oobCode = queryParams.get('oobCode');


  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!oobCode || !mode) return;

    switch (mode) {
      case 'resetPassword':
        // Handle password reset
        verifyPasswordResetCode(auth, oobCode)
          .then(() => {
            // Allow user to reset password
            navigate(`/account/reset-password?oobCode=${oobCode}`);
          })
          .catch(err => {
            setMessage('The password reset link is invalid or expired.');
          });
        break;
      case 'verifyEmail':
        // Handle email verification
        applyActionCode(auth, oobCode)
          .then(() => {
            setMessage('Your email has been verified.');
          })
          .catch(() => {
            setMessage('Failed to verify email.');
          });
        break;
      default:
        setMessage('Unknown action.');
        break;
    }
  }, [oobCode, mode]);

  return (
    <>
      {message ? <p>{message}</p> : <p>Processing...</p>}
    </>
  );
};

export default AuthActions;
