import { createSystem, defaultConfig } from "@chakra-ui/react"

export const system = createSystem(defaultConfig, {
  theme: {
    tokens: {
      fonts: {
        heading: { value: `'Figtree', sans-serif` },
        body: { value: `'Figtree', sans-serif` },
      },
      colors:{
        ss_green: { value: '#005C30' },
        background: { value: '#e4e6eb' },
        surface: { value: '#242526' },
        wash: { value: '#18191a' },
        slate: { value: '#2f4f4ff7' },
        brand: {
          50: { value: '#f0fdf4' },
          100: { value: '#c6f6d5' },
          200: { value: '#9ae6b4' },
          300: { value: '#68d391' },
          400: { value: '#48bb78' },
          500: { value: '#005C30' }, // This is the default color used when `colorScheme="green"`
          600: { value: '#2f855a' },
          700: { value: '#276749' },
          800: { value: '#22543d' },
          900: { value: '#1c4532' },
        },
        green: {
          50: { value: '#f0fdf4' },
          100: { value: '#c6f6d5' },
          200: { value: '#9ae6b4' },
          300: { value: '#68d391' },
          400: { value: '#48bb78' },
          500: { value: '#005C30' }, // This is the default color used when `colorScheme="green"`
          600: { value: '#2f855a' },
          700: { value: '#276749' },
          800: { value: '#22543d' },
          900: { value: '#1c4532' },
          // Add other custom colors or modify existing ones
        },        
      }
    },
  },
})

/*
const colors = {
   
    background: '#FFFFFF',
    surface: '#242526',
    wash: '#18191a',
    muted: '#f0e6f6',
    text: '#e4e6eb',

    
}

const queries = {
    xs: '@media screen and (max-width: 40em)',
    sm: '@media screen and (min-width: 40em) and (max-width: 52em)',
    'sm+': '@media screen and (min-width: 40em)',
    md: '@media screen and (min-width: 52em) and (max-width: 64em)',
    'md+': '@media screen and (min-width: 52em)',
    lg: '@media screen and (min-width: 64em)',
  }
  
export const theme = extendTheme(
  {
    ...baseTheme,
    colors: { 
      ...colors ,
      ...baseTheme.colors,
      green: {
        50: '#f0fdf4',
        100: '#c6f6d5',
        200: '#9ae6b4',
        300: '#68d391',
        400: '#48bb78',
        500: '#005C30', // This is the default color used when `colorScheme="green"`
        600: '#2f855a',
        700: '#276749',
        800: '#22543d',
        900: '#1c4532',
        // Add other custom colors or modify existing ones
      }, 
      brand: {
        50: '#f0fdf4',
        100: '#c6f6d5',
        200: '#9ae6b4',
        300: '#68d391',
        400: '#48bb78',
        500: '#005C30', // This is the default color used when `colorScheme="green"`
        600: '#2f855a',
        700: '#276749',
        800: '#22543d',
        900: '#1c4532',
      }
    },
  },
  queries,
  proTheme,
)

*/