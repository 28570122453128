import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App.tsx';
import * as serviceWorker from "./serviceWorker.js";

import ErrorBoundary from "./pages/ErrorBoundary"

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ErrorBoundary>
    <App tab="home" />
  </ErrorBoundary>
);

serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          
            window.location.reload()
          
        }
      })
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
    }
  },
});