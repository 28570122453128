import {  Flex, Image } from '@chakra-ui/react';

import Logo from "../images/icon_square.jpeg";

const App = () => {

	return(
		<Flex bg='#f0ead6' justifyContent='center' h='100vh' alignItems='center'> 
			<Image height={['6rem']} src={Logo} />
		</Flex>
	)
}

export default App;