import { createContext, useContext, useEffect, useState } from 'react';
import {  onAuthStateChanged, signOut, getIdTokenResult } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db, auth } from '../utils/firebase'; // Adjust the path based on your project structure
import { isArray } from 'highcharts';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null); // State for Firestore data
  const [loading, setLoading] = useState(true);

  // Fetch additional user data from your backend
  const fetchUserData = async (firebaseUser) => {

    if(!firebaseUser) {
      firebaseUser = auth.currentUser;
    }

    try {
        // Fetch user data from Firestore
        const userDocRef = doc(db, 'users', firebaseUser.uid); // Reference the user's document
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
            setUserData(userDocSnapshot.data()); // Set Firestore data to state
        } else {
            console.log('User document does not exist');
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
    }

    try {
      // Get the user's ID token and claims
      const tokenResult = await getIdTokenResult(firebaseUser);

      // Get the user's ID token and claims
      const claims = tokenResult.claims;

      setUser({
        ...firebaseUser,
        ...claims,
      });
    
      return firebaseUser;
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // Function to update or create a user's document in Firestore
  const updateMember = async (updatedData) => {
    try {
      const userDocRef = doc(db, 'users', user.uid);

      // Use setDoc with merge: true to update or create the document
      await setDoc(userDocRef, updatedData, { merge: true });
      setUserData((prevData) => ({ ...prevData, ...updatedData }));

    } catch (error) {
      console.error('Error updating user document:', error);
    }
  };

  // Listen for Firebase auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      setLoading(true);
      if (firebaseUser) {
        await fetchUserData(firebaseUser);
      } else {
        setUser(null);
        setUserData(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [onAuthStateChanged]);

  const signOutUser = async () => {
    await signOut(auth);
    setUser(null); // Clear user state on sign-out
    setUserData(null); // Clear user data on sign-out
  };

  // Function to check if a user has access to a specific content
  const canAccess = (roles) => {
    // roles is an array of roles that are allowed to access the content
    if (!user) return false;
    if (!roles) return true;

    if(!isArray(roles)) {
      roles = roles.split(",");
    }
    // Check if the user's role is included in the roles array
    return roles.some(role => userData.ssRole.includes(role) ) 
  }

  return (
    <AuthContext.Provider value={{ user, userData, loading, signOutUser, fetchUserData, updateMember, canAccess }}>
      {children}
    </AuthContext.Provider>
  );
}

// Custom hook to access the auth context
export function useAuth() {
  return useContext(AuthContext);
}
