import { 
    Center,
    Text,
    Box,
    Image,
    Spinner
} from '@chakra-ui/react'

const LoadingComponent = () => {
  return (
    <Center 
        w='100%'
        h='100%'
        flexDir='column'
    >
      <Box
        position='relative'
        w='200px'
        h='200px'
      >
        <Spinner
            thickness='8px'
            speed='0.55s'
            emptyColor='gray.200'
            color='green'
            size='xl'
            height={200}
            width={200}
        />
        <Image
            src='/images/icons/Sportstats Logo_Secondary.png'
            w='100px'
            h='100px'
            position='absolute'
            top='50%'
            left='50%'
            transform='translate(-50%, -50%)'
        />
      </Box>
      <Text mt={2} fontWeight={600} >... Loading ...</Text>
    </Center>
  );
};

export default LoadingComponent;
